@if (errorsList.length) {
  @for (error of errorsList; track error; let isFirst = $first) {
    @if (isFirst || !isSingleError) {
      @if (errorDictionary && errorDictionary[error]; as errorDescription) {
        <div class="error">
          {{ errorDescription | translate: errorParams && errorParams[error] }}
        </div>
      } @else {
        <ng-container [ngSwitch]="error">
          <div class="error" *ngSwitchCase="'required'">
            {{ 'validationMessageComponent.required' | translate }}
          </div>
          <div class="error" *ngSwitchCase="'minlength'">
            {{
              'validationMessageComponent.minlength'
                | translate: { minValue: errors['minlength']['requiredLength'] }
            }}
          </div>
          <div class="error" *ngSwitchCase="'maxlength'">
            {{
              'validationMessageComponent.maxlength'
                | translate: { maxValue: errors['maxlength']['requiredLength'] }
            }}
          </div>
          <div class="error" *ngSwitchCase="'dateInvalid'">
            {{ 'validationMessageComponent.dateInvalid' | translate }}
          </div>
          <div class="error" *ngSwitchCase="'email'">
            {{ 'validationMessageComponent.email' | translate }}
          </div>
          <div class="error" *ngSwitchCase="'valueNotSelected'">
            {{ 'validationMessageComponent.valueNotSelected' | translate }}
          </div>
          <div class="error" *ngSwitchCase="'phoneNumber'">
            {{ 'validationMessageComponent.phoneNumber' | translate }}
          </div>
        </ng-container>
      }
    }
  }
}
