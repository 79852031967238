import {
  JwtTokens,
  JwtTokensRest,
  LoginRequest,
  LoginRestRequest,
  RegisterRequest,
  RegisterRestRequest,
  ResetPasswordRequest,
  ResetPasswordRestRequest,
} from '../models';

const jwtTokensFromRest = ({ access_token, refresh_token }: JwtTokensRest): JwtTokens => ({
  accessToken: access_token,
  refreshToken: refresh_token,
});

const registerToRest = (register: RegisterRequest): RegisterRestRequest => ({
  ...register,
});

const loginToRest = (login: LoginRequest): LoginRestRequest => ({
  ...login,
});

const resetPasswordToRest = (resetPassword: ResetPasswordRequest): ResetPasswordRestRequest => ({
  ...resetPassword,
});

export const AuthAdapter = {
  jwtTokensFromRest,
  loginToRest,
  resetPasswordToRest,
  registerToRest,
};
