export enum IconName {
  Burger = 'burger',
  OfferBadge = 'offer-badge',
  OfferCalculator = 'offer-calculator',
  OfferCharts = 'offer-charts',
  OfferChat = 'offer-chat',
  OfferDoc = 'offer-doc',
  OfferDollar = 'offer-dollar',
  OfferEdit = 'offer-edit',
  OfferStatistic = 'offer-statistic',
  OfferTags = 'offer-tags',
  OfferWallet = 'offer-wallet',
}

export const ICON_NAMES: IconName[] = Object.values(IconName);
