<cab-section-header [title]="'strongProduct.header' | translate" />

<div class="strong-product__cards">
  <div class="strong-product__card">
    <img class="strong-product__card-img" src="assets/game.png" alt="img" />
    <p class="strong-product__card-description">
      {{ 'strongProduct.gameTitle' | translate }}
    </p>
  </div>
  <div class="strong-product__card">
    <img class="strong-product__card-img" src="assets/geo.png" alt="img" />
    <p class="strong-product__card-description">
      {{ 'strongProduct.geoTitle' | translate }}
    </p>
  </div>
  <div class="strong-product__card">
    <img class="strong-product__card-img" src="assets/money.png" alt="img" />
    <p class="strong-product__card-description">
      {{ 'strongProduct.moneyTitle' | translate }}
    </p>
  </div>
</div>

<h4
  class="strong-product__caption"
  [innerHTML]="'lifetimeValue.header' | translate | headerDecode"
></h4>
<div class="strong-product__tags">
  @for (LTVTag of LTVTags; track $index) {
    <span class="strong-product__tag">{{ LTVTag | translate }}</span>
  }
</div>

<h4
  class="strong-product__caption"
  [innerHTML]="'providers.desktopHeader' | translate | headerDecode"
></h4>
<div class="strong-product__providers">
  @for (provider of providers; track $index) {
    <div class="strong-product__provider">
      <img
        class="strong-product__provider-img"
        src="/assets/providers/{{ provider }}.png"
        alt="provider-{{ provider }}"
      />
    </div>
  }
  <div class="strong-product__provider">И многие другие</div>
</div>

<cab-advertisement-line color="secondary" rotation="up" />
