import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { NavigationService } from '../../services/navigation.service';
import { NavigationLink } from '../../models/navigation-link.interface';

@Component({
  selector: 'cab-nav, [cabNav]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.scss',
  imports: [TranslateModule, RouterLink],
})
export class NavComponent implements OnInit {
  links: NavigationLink[];

  private _navigationService = inject(NavigationService);

  ngOnInit(): void {
    this.links = this._navigationService.getNavigationLinks();
  }
}
