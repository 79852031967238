<div @slideIn @slideOut class="navigation">
  <div class="navigation__links-container">
    <nav cabNav></nav>
    <cab-icon class="navigation__close" iconName="close" (click)="onClose()" />
  </div>

  <menu cabUserMenu></menu>

  <div class="navigation__footer">
    <cab-social-links />
    <cab-languages [isMobile]="true" />
  </div>
</div>
