import { GlobalPositionStrategy, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Injectable, Injector, inject } from '@angular/core';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';

import { take } from 'rxjs';

import { SidebarOverlayRef } from './side-bar-overlay-ref';

@Injectable()
export class SidebarOverlayService {
  private readonly _injector = inject(Injector);
  private readonly _overlay = inject(Overlay);

  private _overlayRef: OverlayRef = null;

  open<T>(component: ComponentType<T>): SidebarOverlayRef {
    this._overlayRef = this._createOverlay();

    this._overlayRef
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => this.close());

    return this._attachSidebarRef<T>(component);
  }

  close(): void {
    if (this._overlayRef) {
      this._overlayRef.dispose();
      this._overlayRef = null;
    }
  }

  private _attachSidebarRef<T>(component: ComponentType<T>): SidebarOverlayRef {
    const sidebarRef = new SidebarOverlayRef(this._overlayRef);
    const portalInjector = Injector.create({
      parent: this._injector,
      providers: [{ provide: SidebarOverlayRef, useValue: sidebarRef }],
    });

    const portal = new ComponentPortal(component, null, portalInjector);

    this._overlayRef.attach(portal);

    return sidebarRef;
  }

  private _createOverlay(): OverlayRef {
    return this._overlay.create({
      disposeOnNavigation: true,
      positionStrategy: this._positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cabinet-home-overlay-backdrop',
      panelClass: 'cabinet-home-overlay-panel',
      scrollStrategy: this._overlay.scrollStrategies.block(),
    });
  }

  private get _positionStrategy(): GlobalPositionStrategy {
    return this._overlay.position().global().centerHorizontally().centerVertically();
  }
}
