import { InjectionToken } from '@angular/core';

import { Observable } from 'rxjs';
import { GeoResponse } from '@shared/models';

export interface GeoProvider {
  getGeo(): Observable<GeoResponse>;
}

export const GEO_PROVIDER_TOKEN = new InjectionToken<GeoProvider>('GeoProvider');
