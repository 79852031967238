<ng-container *cabLet="control.touch$ | async as controlTouched">
  <div class="cabinet-control__content">
    <ngx-intl-tel-input
      [formControl]="control"
      [customPlaceholder]="config.customPlaceholder"
      [enableAutoCountrySelect]="config.enableAutoCountrySelect"
      [selectFirstCountry]="config.selectFirstCountry"
      [selectedCountryISO]="config.selectedCountryISO"
      [maxLength]="config.maxLength"
      [phoneValidation]="config.phoneValidation"
      [separateDialCode]="config.separateDialCode"
      [numberFormat]="config.numberFormat"
    />
  </div>
  @if (controlTouched && (control.invalid$ | async) && showValidationMessage) {
    <cab-validation-message
      [errors]="control.errors$ | async"
      [errorDictionary]="{ validatePhoneNumber: 'validationMessageComponent.phoneNumber' }"
    />
  }
</ng-container>
