<cab-section-header [title]="'offers.header' | translate" />

<div class="offers__wrapper">
  @for (item of cards; track $index) {
    <div class="offers__item">
      <img class="offers__item-img" [src]="item.icon" [alt]="item.title | translate" />
      <span class="offers__item-title">{{ item.title | translate }}</span>
      @if (item.tags) {
        <div class="offers__tags">
          @for (tag of item.tags; track $index) {
            <span class="offers__tag">{{ tag | translate }}</span>
          }
        </div>
      }
    </div>
  }
</div>

<p class="offers__description">
  <span> *{{ 'offers.hybridOfferDescription' | translate }} </span>
  <span> **{{ 'offers.cpaOfferDescription' | translate }} </span>
</p>
