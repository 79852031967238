<ng-container *cabLet="control.touch$ | async as controlTouched">
  <div class="cabinet-control__content">
    <input
      class="cabinet-control__input"
      #inputRef
      [type]="controlType"
      [id]="controlId"
      [ngClass]="{
        'cabinet-control__input_invalid': controlTouched && control.invalid,
        'cabinet-control__input_padding': !!iconTemplate,
      }"
      [readonly]="isReadonly"
      [placeholder]="placeholder"
      (focus)="setIsFocused(true)"
      (blur)="setIsFocused(false)"
      [formControl]="control"
      [autocomplete]="autocomplete"
      [attr.data-testid]="controlTestId"
      [attr.inputmode]="inputMode"
    />
    @if (label) {
      <label
        [for]="controlId"
        class="cabinet-control__label"
        [ngClass]="{
          'cabinet-control__label_invalid': controlTouched && control.invalid,
          'cabinet-control__label_active': (control.value$ | async) || placeholder || isLabelPinned,
          'cabinet-control__label_small': labelSize === 's',
        }"
      >
        {{ label }}
      </label>
    }
    @if (iconTemplate) {
      <div class="cabinet-control__icon">
        <ng-container [ngTemplateOutlet]="iconTemplate" />
      </div>
    }
  </div>
  @if (controlTouched && (control.invalid$ | async) && showValidationMessage) {
    <cab-validation-message
      [errors]="control.errors$ | async"
      [errorDictionary]="errorDictionary"
    />
  }
</ng-container>
