import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'headerDecode',
  standalone: true,
})
export class HeaderDecodePipe implements PipeTransform {
  transform(value: string): string {
    const normalizedValue = value
      .replace(/{highlight}/g, '<span class="text-highlighted">')
      .replace(/{\/highlight}/g, '</span>')
      .replace(/{break}/g, '</br>')
      .replace(/{withBorder}/g, '<span class="text-with-border">')
      .replace(/{\/withBorder}/g, '</span>');

    return normalizedValue;
  }
}
