import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { Review } from './models/review.interface';
import { ReviewsService } from './services/reviews.service';
import { ReviewCardComponent } from './components/review-card.component';
import { SectionHeaderComponent } from '@shared/components';

@Component({
  selector: 'cab-reviews',
  standalone: true,
  templateUrl: './reviews.component.html',
  styleUrl: './reviews.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SectionHeaderComponent, TranslateModule, ReviewCardComponent],
  providers: [ReviewsService],
})
export class ReviewsComponent {
  reviews: Review[];

  private _reviewsService = inject(ReviewsService);

  constructor() {
    this.reviews = this._reviewsService.getReviews();
  }
}
