import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { AdvertisementLineComponent, SectionHeaderComponent } from '@shared/components';
import { HeaderDecodePipe } from '@shared/ui';
import { NgOptimizedImage } from '@angular/common';

const PROVIDERS = [
  'amusnet',
  'evolution',
  'gamzix',
  'spribe',
  'pragmatic-play',
  'play-tech',
  'push-gaming',
  'play-and-go',
  'net-ent',
  'b-gaming',
  'micro-gaming',
] as const;

@Component({
  selector: 'cab-strong-product',
  standalone: true,
  templateUrl: './strong-product.component.html',
  styleUrl: './strong-product.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SectionHeaderComponent,
    TranslateModule,
    HeaderDecodePipe,
    AdvertisementLineComponent,
    NgOptimizedImage,
  ],
})
export class StrongProductComponent {
  readonly LTVTags: string[] = [
    'lifetimeValue.localPaymentSystems',
    'lifetimeValue.promoAndTournaments',
    'lifetimeValue.playerBonuses',
    'lifetimeValue.vipPlayerServices',
    'lifetimeValue.professionalSupport',
    'lifetimeValue.kc',
  ];

  readonly providers = PROVIDERS;
}
