@let isDesktopMode = isDesktopMode$ | async;

<cab-start [isDesktopMode]="isDesktopMode" />
<cab-strong-product />
<cab-offers />
<cab-career [isDesktopMode]="isDesktopMode" />
<cab-faq [isDesktopMode]="isDesktopMode" />
<cab-conferences [isDesktopMode]="isDesktopMode" />
<cab-reviews />
<cab-slider />
