import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import {
  ButtonDirective,
  DialogRef,
  DialogService,
  DialogWrapperComponent,
  IconComponent,
  SimpleInputComponent,
} from '@shared/components';

import { AuthService } from '../../services';
import { ResetPasswordComponent } from '../reset-password';

@Component({
  selector: 'cab-login',
  standalone: true,
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    IconComponent,
    SimpleInputComponent,
    DialogWrapperComponent,
    ButtonDirective,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class LoginComponent {
  formGroup = new FormGroup({
    login: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });
  isPasswordVisible = false;

  private _authService = inject(AuthService);
  private _destroyRef = inject(DestroyRef);
  private _dialogRef = inject(DialogRef);
  private _dialogService = inject(DialogService);
  private _router = inject(Router);

  handleClose(): void {
    this._dialogRef.close();
  }

  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  openResetPassword(): void {
    this._dialogRef.close();
    this._dialogService.open(ResetPasswordComponent);
  }

  handleBtnClick(): void {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();

      return;
    }

    const { login, password } = this.formGroup.value;
    this._authService
      .login({ login, password })
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: jwtTokens => {
          this._authService.setTokens(jwtTokens);
          this._dialogRef.close();
          this._router.navigate(['/dashboard']);
        },
        error: error => console.error(error), // TODO: add toaster
      });
  }
}
