import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import {
  AdvertisementLineComponent,
  ButtonDirective,
  DialogService,
  IconComponent,
  SectionHeaderComponent,
} from '@shared/components';
import { ValueLabel } from '@shared/models';
import { RegisterComponent } from '@auth/core';

@Component({
  selector: 'cab-career',
  standalone: true,
  templateUrl: './career.component.html',
  styleUrl: './career.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AdvertisementLineComponent,
    ButtonDirective,
    SectionHeaderComponent,
    TranslateModule,
    IconComponent,
  ],
})
export class CareerComponent {
  @Input({ required: true }) isDesktopMode: boolean;

  careerSteps: ValueLabel[] = [
    {
      label: 'career.stepOne',
      value: 'career.stepOneDescription',
    },
    {
      label: 'career.stepTwo',
      value: 'career.stepTwoDescription',
    },
    {
      label: 'career.stepThree',
      value: 'career.stepThreeDescription',
    },
    {
      label: 'career.stepFour',
      value: 'career.stepFourDescription',
    },
    {
      label: 'career.stepFive',
      value: 'career.stepFiveDescription',
    },
  ];

  private readonly _dialogService = inject(DialogService);

  openRegistrationDialog(): void {
    this._dialogService.open(RegisterComponent);
  }
}
