import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { DocPageComponent } from '../doc-page/doc-page.component';
import { StaticContentPage } from '../../models/static-content-page.enum';

@Component({
  selector: 'cab-affiliate-agreement',
  standalone: true,
  templateUrl: './affiliate-agreement.component.html',
  styleUrl: './affiliate-agreement.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DocPageComponent, TranslateModule],
})
export class AffiliateAgreementComponent {
  StaticContentPage = StaticContentPage;
}
