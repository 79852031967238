import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { HeaderDecodePipe } from '@shared/ui';

@Component({
  selector: 'cab-section-header',
  standalone: true,
  styleUrls: ['./section-header.component.scss'],
  template: `<h3 class="title" [innerHTML]="title | headerDecode"></h3>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [HeaderDecodePipe],
})
export class SectionHeaderComponent {
  @Input({ required: true }) title: string;
}
