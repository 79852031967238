import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'cab-mobile-scroll-container',
  standalone: true,
  template: '<ng-content />',
  styleUrl: './mobile-scroll-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileScrollContainerComponent {}
