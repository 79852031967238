import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

import { SpinnerDirective } from './spinner.directive';

@Component({
  selector: 'cab-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, SpinnerDirective],
})
export class SpinnerComponent {
  @Input() label: string;

  @Input() size: 'xs' | 's' | 'm' = 'm';
}
