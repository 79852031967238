import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/components';
import { StaticContentPage, staticContentPageRoutes } from '@static-pages';

import { SocialLinksComponent } from '../social-links/social-links.component';

@Component({
  selector: 'cab-footer, [cabFooter]',
  standalone: true,
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, IconComponent, RouterLink, SocialLinksComponent],
})
export class FooterComponent {
  @Input({ required: true }) isDesktopMode: boolean;

  readonly affiliateAgreementRoute = staticContentPageRoutes[StaticContentPage.AffiliateAgreement];
  readonly privacyPolicyRoute = staticContentPageRoutes[StaticContentPage.PrivacyPolicy];
}
