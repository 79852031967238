<cab-dialog-wrapper [caption]="'auth.loginTitle' | translate" (closeDialog)="handleClose()">
  <ng-container dialogBody>
    <form [formGroup]="formGroup">
      <cab-simple-input
        formControlName="login"
        controlId="login"
        [placeholder]="'auth.email' | translate"
      />
      <cab-simple-input
        formControlName="password"
        controlId="password"
        [iconTemplate]="icon"
        [placeholder]="'auth.password' | translate"
        [controlType]="isPasswordVisible ? 'text' : 'password'"
      />
      <span class="label" (click)="openResetPassword()">{{
        'auth.forgotPassword' | translate
      }}</span>
    </form>
  </ng-container>
  <ng-container dialogFooter>
    <button
      cabButton
      size="m"
      cabButtonType="primary"
      fullWidth="true"
      type="button"
      (click)="handleBtnClick()"
      class="login-btn"
    >
      {{ 'auth.login' | translate }}
    </button>
  </ng-container>
</cab-dialog-wrapper>

<ng-template #icon>
  <cab-icon
    class="icon"
    [iconName]="isPasswordVisible ? 'eye-open' : 'eye-closed'"
    (click)="togglePasswordVisibility()"
  />
</ng-template>
