import { Directive, Input, TemplateRef, ViewContainerRef, inject } from '@angular/core';

import { SpinnerComponent } from './spinner.component';

/**
 * Directive to replace view with an inline spinner
 */
@Directive({
  selector: '[cabSpinner]',
  standalone: true,
})
export class SpinnerDirective {
  /**
   * Unset by default, this forces it to evaluate upon first render.
   */
  private _isSpinning: boolean = null;

  private _templateRef = inject(TemplateRef<unknown>);
  private _viewContainer = inject(ViewContainerRef);

  @Input() set cabSpinner(condition: boolean) {
    if (condition !== this._isSpinning) {
      this._isSpinning = null;
      this._viewContainer.clear();
      this._isSpinning = condition;

      if (condition) {
        this._addSpinner();
      } else {
        this._viewContainer.createEmbeddedView(this._templateRef);
      }
    }
  }

  private _addSpinner() {
    this._viewContainer.createComponent(SpinnerComponent);
  }
}
