import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { NgClass } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { IconComponent } from '../icon';

const ADVERTISEMENT_TEXT_LIST = [
  'advertisement.bornToConvert',
  'advertisement.availableGeos',
  'advertisement.cpa',
  'advertisement.regToDep',
  'advertisement.statistics',
];

@Component({
  selector: 'cab-advertisement-line',
  standalone: true,
  templateUrl: './advertisement-line.component.html',
  styleUrl: './advertisement-line.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, IconComponent, NgClass],
})
export class AdvertisementLineComponent {
  @Input() set color(color: 'primary' | 'secondary') {
    this._color = color;
    this._updateClassList();
  }

  @Input() set rotation(rotation: 'down' | 'up') {
    this._rotation = rotation;
    this._updateClassList();
  }

  lineCount = [1, 2];
  advertisementTags = ADVERTISEMENT_TEXT_LIST;

  private _rotation = 'down';
  private _color = 'primary';

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostBinding('class')
  private _parentClassList =
    `advertisement-line advertisement-line_${this._rotation} advertisement-line_${this._color}`;

  private _updateClassList() {
    this._parentClassList = `advertisement-line advertisement-line_${this._rotation} advertisement-line_${this._color}`;
  }
}
