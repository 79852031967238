import { GeoResponse, GeoRestResponse, languageKeyList, Languages } from '@shared/models';
import { CountryISO } from 'ngx-intl-tel-input-gg';

const fromRest = (geoRest: GeoRestResponse): GeoResponse => {
  const isSupportedLang = languageKeyList.includes(geoRest.country_code);
  return {
    countryCode: geoRest.country_code as CountryISO,
    language: isSupportedLang ? (geoRest.country_code as Languages) : Languages.EN,
  };
};

export const GeoAdapter = {
  fromRest,
};
