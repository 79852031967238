import { DatePipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { IconComponent } from '@shared/components';
import { SLIDER_ITEMS } from './const/slider.constants';

@Component({
  selector: 'cab-slider',
  standalone: true,
  templateUrl: './slider.component.html',
  styleUrl: './slider.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, IconComponent, DatePipe],
})
export class SliderComponent {
  isCollapsed = signal(false);
  currentIndex = signal(0);
  carouselItems = SLIDER_ITEMS;

  expendSlider(): void {
    this.isCollapsed.set(false);
  }

  collapseSlider(): void {
    this.isCollapsed.set(true);
  }

  getTransform(): string {
    return `translateX(-${this.currentIndex() * 100}%)`;
  }

  next() {
    this.currentIndex.set((this.currentIndex() + 1) % this.carouselItems.length);
  }

  prev() {
    this.currentIndex.set(
      (this.currentIndex() - 1 + this.carouselItems.length) % this.carouselItems.length,
    );
  }
}
