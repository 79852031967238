<cab-section-header [title]="'faq.header' | translate" />

<div class="faq__wrapper">
  @for (item of items; track $index) {
    <div class="faq__item" [class.open]="item.open">
      <button (click)="toggleItem(item)" type="button" class="faq__header">
        <cab-icon
          class="faq__item-icon"
          [iconName]="item.open ? 'minus' : 'add'"
          [size]="isDesktopMode ? 'm' : 's'"
        />
        {{ item.title | translate }}
      </button>
      <div class="faq__content" [@openClose]="item.open ? 'open' : 'closed'">
        <p [innerHTML]="item.content | translate | decodedSafeHtml"></p>
      </div>
    </div>
  }
</div>
