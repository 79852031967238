@for (item of actions; track $index) {
  <button
    class="user-menu__item"
    cabButton
    cabButtonType="ghost"
    [isActive]="item.isActive"
    (click)="handleItemClick(item)"
  >
    {{ item.label | translate }}
  </button>
}
<ng-content></ng-content>
