<div class="content">
  <div class="slider">
    <div class="slider__figure figure-top">
      <div class="figure-top--left"></div>
      <div class="figure-top--right"></div>
    </div>

    <div class="slider__content">
      <div class="slider__header">
        <span class="slider__text">{{ 'Оцени как мы продвигаем индустрию' }}</span>
        @if (isCollapsed()) {
          <div class="slider__divider">
            <div class="divider__arrow"></div>
          </div>
        }
        <div class="slider__toggle">
          @if (isCollapsed()) {
            <cab-icon iconName="add" size="m" (click)="expendSlider()" />
          } @else {
            <cab-icon iconName="minus" size="m" (click)="collapseSlider()" />
          }
        </div>
      </div>

      <div class="slider__inner" [ngClass]="{ collapsed: isCollapsed() }">
        <cab-icon iconName="chevron-left" size="s" class="arrow arrow--prev" (click)="prev()" />
        <cab-icon iconName="chevron-right" size="s" class="arrow arrow--next" (click)="next()" />
        <div class="slider__carousel">
          <ul class="carousel__list" [style.transform]="getTransform()">
            @for (item of carouselItems; track item.imageUrl) {
              <li class="carousel__item">
                <div class="item__header">
                  <span class="header__title">{{ item.title }}</span>
                  <span class="header__location">{{ item.location }}</span>
                  <span class="header__date">{{ item.date | date: 'dd.MM.yyyy' }}</span>
                </div>
                <div class="divider"></div>
                <div class="item__content">
                  <img [src]="item.imageUrl" fill [alt]="item.title" loading="eager" />
                </div>
              </li>
            }
          </ul>
        </div>
      </div>
    </div>

    <div class="slider__figure figure-bottom">
      <div class="figure-bottom--left"></div>
      <div class="figure-bottom--right"></div>
    </div>
  </div>
</div>

<!-- svg for custom slider wrapper shape. Usage in css clip-path: url(#curvedClip); -->
<svg width="0" height="0">
  <defs>
    <clipPath id="curvedClip" clipPathUnits="objectBoundingBox">
      <path d="M 0 0 C 0.3 0, 0.7 1, 1 1 L 1 0 L 0 0 Z" />
    </clipPath>
  </defs>
</svg>
