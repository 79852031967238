import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { RegisterRequest, ResetPasswordRequest } from '../models';
import { JwtTokens } from '../models/jwt-token.interface';
import { LoginRequest } from '../models/login-request.interface';
import { AUTH_PROVIDER_TOKEN } from '../providers/auth.provider';
import { setAccessToken, setRefreshToken } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _authProvider = inject(AUTH_PROVIDER_TOKEN);

  isTokenValid(): Observable<boolean> {
    return this._authProvider.isTokenValid();
  }

  login(request: LoginRequest): Observable<JwtTokens> {
    return this._authProvider.login(request);
  }

  register(request: RegisterRequest): Observable<void> {
    return this._authProvider.register(request);
  }

  resetPassword(request: ResetPasswordRequest): Observable<void> {
    return this._authProvider.resetPassword(request);
  }

  setTokens({ accessToken, refreshToken }: JwtTokens): void {
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
  }
}
