import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { IconName, SectionHeaderComponent } from '@shared/components';

import { OfferCard } from './models/offer-card.interface';

@Component({
  selector: 'cab-offers',
  standalone: true,
  templateUrl: './offers.component.html',
  styleUrl: './offers.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SectionHeaderComponent, TranslateModule],
})
export class OffersComponent {
  cards: OfferCard[] = [
    {
      icon: `/assets/offers/${IconName.OfferTags}.svg`,
      title: 'offers.referral',
    },
    {
      icon: `/assets/offers/${IconName.OfferDollar}.svg`,
      title: 'offers.paymentModel',
      tags: ['offers.cpa', 'offers.revenueShare', 'offers.revenueSharePlus', 'offers.hybrid'],
    },
    {
      icon: `/assets/offers/${IconName.OfferCharts}.svg`,
      title: 'offers.partnersOffers',
      tags: ['offers.application', 'offers.preLanding', 'offers.creative'],
    },
    {
      icon: `/assets/offers/${IconName.OfferWallet}.svg`,
      title: 'offers.cpaTitle',
    },
    {
      icon: `/assets/offers/${IconName.OfferCalculator}.svg`,
      title: 'offers.kpi',
    },
    {
      icon: `/assets/offers/${IconName.OfferEdit}.svg`,
      title: 'offers.regToDep',
    },
    {
      icon: `/assets/offers/${IconName.OfferBadge}.svg`,
      title: 'offers.conferences',
    },
    {
      icon: `/assets/offers/${IconName.OfferChat}.svg`,
      title: 'offers.support',
    },
    {
      icon: `/assets/offers/${IconName.OfferStatistic}.svg`,
      title: 'offers.statistics',
      tags: ['offers.toPlayers', 'offers.cohord', 'offers.promo'],
    },
    {
      icon: `/assets/offers/${IconName.OfferDoc}.svg`,
      title: 'offers.revenueShare',
    },
  ];
}
