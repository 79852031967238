import { InjectionToken } from '@angular/core';

import { Observable } from 'rxjs';

import { JwtTokens, LoginRequest, RegisterRequest, ResetPasswordRequest } from '../models';

export interface AuthProvider {
  isTokenValid(): Observable<boolean>;
  resetPassword(request: ResetPasswordRequest): Observable<void>;
  register(request: RegisterRequest): Observable<void>;
  login(request: LoginRequest): Observable<JwtTokens>;
}

export const AUTH_PROVIDER_TOKEN = new InjectionToken<AuthProvider>('AuthProvider');
