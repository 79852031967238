import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AsyncPipe } from '@angular/common';

import { Observable } from 'rxjs';
import { CommonMediaQueries, MediaScreenService } from '@shared/services';

import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';

@Component({
  selector: 'cab-shell',
  standalone: true,
  templateUrl: './shell.component.html',
  styleUrl: './shell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet, HeaderComponent, FooterComponent, AsyncPipe],
})
export class ShellComponent implements OnInit {
  isDesktopMode$: Observable<boolean>;

  private _mediaScreenService = inject(MediaScreenService);

  ngOnInit(): void {
    this.isDesktopMode$ = this._mediaScreenService.mediaMatcher(CommonMediaQueries.XL);
  }
}
