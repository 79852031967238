import { Injectable } from '@angular/core';

import { Review } from '../models/review.interface';

@Injectable()
export class ReviewsService {
  getReviews(): Review[] {
    return [
      {
        title: 'Traffic Devils',
        picture: '/assets/reviews/traffic.svg',
        description:
          'PIN-UP Partners объединяет в себе качественный и постоянно развивающийся продукт, сильную команду аффилиэйтов и ' +
          'качественный менеджмент на всех уровнях компании. Работая с ними, вы можете быть уверены в своевременных выплатах, ' +
          'стабильном конверте и регулярном появлении новых гео. PIN-UP Partners умеет работать со всеми источниками трафика, ' +
          'что дает свободу выбора и возможность поиска наилучшего источника.',
      },
      {
        title: 'Gagarin Arbitrage Team',
        picture: '/assets/reviews/gagarin.svg',
        description:
          'Работаем с PIN-UP Partners фактически с первых дней их основания. Прошло немало времени, мы значительно выросли, ' +
          'но наши отношения только улучшились, а объемы увеличились. ‘Уважаем и любим ребят за честный подход к работе и ' +
          'открытость в диалоге. Если прямо ответить на вопрос: «Рекомендуем ли мы работать с PIN-UP Partners?», наш ответ прост: «Да».',
      },
      {
        title: 'Trident',
        picture: '/assets/reviews/trident.svg',
        description:
          'PIN-UP Partners - партнер, с которым работается легко и качественно. Всегда адекватный и быстрый фидбек. ' +
          'Никогда не было каких-либо эксцессов или проблем во взаимодействии и выплатах. Все всегда четко и в срок. ' +
          'Это один из самых сильных продуктов на рынке!',
      },
    ];
  }
}
