<cab-section-header [title]="'career.header' | translate" />

<div class="career__steps">
  @for (step of careerSteps; track $index) {
    <div class="career__step">
      <div class="career__step-header">
        <cab-icon iconName="checked" size="2xl" />
        @if (!$last) {
          <hr />
        }
      </div>
      <span>{{ step.label | translate }}</span>
      <span class="career__step-description">{{ step.value | translate }}</span>
    </div>
  }
</div>

<button
  class="career__cta"
  cabButton
  cabButtonType="tertiary"
  size="cta"
  [size]="isDesktopMode ? 'cta' : 's'"
  (click)="openRegistrationDialog()"
>
  {{ 'career.becomeAPartner' | translate }}
</button>

<cab-advertisement-line />
