<cab-dialog-wrapper [caption]="'auth.registerTitle' | translate" (closeDialog)="handleClose()">
  <ng-container dialogBody>
    <form [formGroup]="formGroup">
      <cab-simple-input
        formControlName="name"
        controlId="name"
        [placeholder]="'auth.name' | translate"
      />
      <cab-simple-input
        formControlName="email"
        controlId="email"
        [placeholder]="'auth.email' | translate"
      />
      <cab-tel-input formControlName="phoneData" controlId="phoneData" />
      <div formGroupName="messenger">
        <cab-select
          controlId="messengerType"
          formControlName="type"
          [itemList]="messengers"
          [placeholder]="'auth.messenger' | translate"
        />
        @if (showMessengerLoginCtrl) {
          <cab-simple-input
            formControlName="login"
            controlId="login"
            [placeholder]="'auth.messengerLogin' | translate"
          />
        }
      </div>
      <cab-simple-input
        formControlName="password"
        controlId="password"
        [iconTemplate]="icon"
        [placeholder]="'auth.password' | translate"
        [controlType]="isPasswordVisible ? 'text' : 'password'"
      />
    </form>
  </ng-container>
  <ng-container dialogFooter>
    <button
      cabButton
      size="m"
      cabButtonType="primary"
      fullWidth="true"
      type="button"
      (click)="handleBtnClick()"
      class="register-btn"
    >
      {{ 'auth.login' | translate }}
    </button>
  </ng-container>
</cab-dialog-wrapper>

<ng-template #icon>
  <cab-icon
    class="icon"
    [iconName]="isPasswordVisible ? 'eye-open' : 'eye-closed'"
    (click)="togglePasswordVisibility()"
  />
</ng-template>
