import { ChangeDetectionStrategy, Component, forwardRef, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe } from '@angular/common';

import { NgxIntlTelInputModule, PhoneNumberFormat } from 'ngx-intl-tel-input-gg';
import { LetDirective } from '@shared/ui';
import { GeoService } from '@shared/services';

import { AbstractInputComponent } from '../abstract-input.component';
import { ValidationMessageComponent } from '../validation-message';
import { NgxIntlTelConfig } from './tel-input.model';

@Component({
  standalone: true,
  selector: 'cab-tel-input',
  styleUrl: './tel-input.component.scss',
  templateUrl: './tel-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    AsyncPipe,
    ValidationMessageComponent,
    LetDirective,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TelInputComponent),
      multi: true,
    },
  ],
})
export class TelInputComponent extends AbstractInputComponent<string | number> {
  readonly geoService = inject(GeoService);

  config: NgxIntlTelConfig = {
    customPlaceholder: '73 898 67 61',
    enableAutoCountrySelect: true,
    selectFirstCountry: false,
    selectedCountryISO: this.geoService.userCountryCode,
    maxLength: 15,
    phoneValidation: true,
    separateDialCode: true,
    numberFormat: PhoneNumberFormat.International,
  };
}
