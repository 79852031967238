import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  HostBinding,
  Input,
  booleanAttribute,
  inject,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe, NgClass } from '@angular/common';

import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ControlValueAccessor, FormControl } from '@ngneat/reactive-forms';
import { SelectDropdownItem } from '@shared/models';

import { ValidationMessageComponent } from '../input/validation-message';

@Component({
  selector: 'cab-select',
  standalone: true,
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgSelectModule,
    FormsModule,
    ValidationMessageComponent,
    TranslateModule,
    ReactiveFormsModule,
    AsyncPipe,
    NgClass,
  ],
})
export class SelectComponent<T> extends ControlValueAccessor<T> implements DoCheck, AfterViewInit {
  @Input({ required: true }) set itemList(itemList: SelectDropdownItem<T>[]) {
    this.items = itemList?.map(({ value, label }) => ({
      value,
      label: label ? this._translateService.instant(label) : value,
    }));
  }

  /**
   * Id of control and label for
   */
  @Input({ required: true }) controlId: string;
  /**
   * Placeholder for input
   */
  @Input() placeholder = '';
  /**
   * Indicates if input is non-editable and readonly
   */
  @Input({ transform: booleanAttribute }) isReadonly: boolean;
  /**
   * Is error message visible
   */
  @Input({ transform: booleanAttribute }) showValidationMessage = true;
  /**
   * Optional error dictionary for custom errors support
   * Key - custom error key, value - translation key for error txt
   */
  @Input() errorDictionary: Record<string, string> = {};
  /**
   * Test id
   */
  @Input() controlTestId: string;

  @Input({ transform: booleanAttribute }) isClearable = false;

  @Input({ transform: booleanAttribute }) isSearchable = false;

  @Input({ transform: booleanAttribute })
  @HostBinding('class.default-width')
  isDefaultWidth = true;

  @Output() scrollEnd = new EventEmitter<void>();

  @ViewChild('select') private _select: NgSelectComponent;

  items: SelectDropdownItem<T>[] = [];

  isDropdownOpened = false;

  selectControl = new FormControl<T>(null);

  ngControl = inject(NgControl, { self: true, optional: true });

  private _cdr = inject(ChangeDetectorRef);
  private _translateService = inject(TranslateService);

  constructor() {
    super();

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    this.selectControl.validator = this.ngControl.control.validator;
  }

  ngDoCheck(): void {
    if (this.ngControl.touched) {
      this.selectControl.markAsTouched();
      this._cdr.markForCheck();
    }
  }

  writeValue(value: T): void {
    this.selectControl.setValue(value, { emitEvent: false });
  }

  override registerOnChange(fn: (value: T) => void): void {
    this.selectControl.valueChanges.subscribe(fn);
  }

  changeDropdownState(): void {
    this.isDropdownOpened = !this.isDropdownOpened;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.selectControl.disable() : this.selectControl.enable();
  }
}
