import { Slide } from '../model/slide.interface';

export const SLIDER_ITEMS: Slide[] = [
  {
    title: 'Padel tournament',
    location: 'Yerevan',
    date: new Date(),
    imageUrl: '/assets/slider/card-1.png',
  },
  {
    title: 'Get rich or burn trying',
    location: 'drop merch',
    date: new Date(),
    imageUrl: '/assets/slider/card-2.png',
  },
];
