<cab-dialog-wrapper [caption]="'auth.resetPassword' | translate" (closeDialog)="handleClose()">
  <ng-container dialogBody>
    @if (isResetSuccess) {
      <span class="label success">{{ 'auth.resetPasswordSuccess' | translate }}</span>
    } @else {
      <form [formGroup]="formGroup">
        <cab-simple-input
          formControlName="email"
          controlId="email"
          [placeholder]="'auth.email' | translate"
        />
        <span class="label">{{ 'auth.resetPasswordHint' | translate }}</span>
      </form>
    }
  </ng-container>
  <ng-container dialogFooter>
    @if (isResetSuccess) {
      <button
        cabButton
        size="m"
        cabButtonType="primary"
        fullWidth="true"
        type="button"
        (click)="openLoginModal()"
      >
        {{ 'auth.backToLogin' | translate }}
      </button>
    } @else {
      <button
        cabButton
        size="m"
        cabButtonType="primary"
        fullWidth="true"
        type="button"
        (click)="handleBtnClick()"
      >
        {{ 'auth.send' | translate }}
      </button>
    }
  </ng-container>
</cab-dialog-wrapper>
