import { Conference } from '../models/conference.interface';

export const CONFERENCE_LIST: Conference[] = [
  {
    url: '#',
    logoUrl: '/assets/conferences/conference-aw.svg',
    title: 'AW Europe Hungary',
    date: {
      from: new Date(),
      to: new Date(),
    },
    location: 'Budapest',
  },
  {
    url: '#',
    logoUrl: '/assets/conferences/conference-igb.svg',
    title: 'IGB Live Amsterdam',
    date: {
      from: new Date(),
      to: new Date(),
    },
    location: 'Amsterdam',
  },
];
