import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { NgSwitch, NgSwitchCase } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

/**
 * Temporary hack due to Jest error with externally imported type or interface when using custom decorator on property
 * Check if it can be removed (fixed) after next dep upgrades
 */
type ValidationErrorsPrivate = ValidationErrors;

@Component({
  selector: 'cab-validation-message',
  standalone: true,
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss'],
  imports: [TranslateModule, NgSwitch, NgSwitchCase],
})
export class ValidationMessageComponent {
  /**
   * Control errors
   */
  @Input()
  set errors(errors: ValidationErrorsPrivate) {
    this._errors = errors;
    this.errorsList = this._getErrorsList(errors);
  }
  get errors(): ValidationErrors {
    return this._errors;
  }

  /**
   * Errors dictionary. Dictionary which contain error key as a key of record, and error text as a value
   * Adds ability to not extend template of ValidationMessageComponent each time when new errors for different components added
   */
  @Input() errorDictionary: Record<string, string>;

  /**
   * Errors params. They are needed for translations when params needed.
   */
  @Input() errorParams: Record<string, Record<string, string>>;

  /**
   * Should only one error be displayed even if we have few errors
   */
  @Input() isSingleError = false;

  errorsList: string[];

  private _errors: ValidationErrorsPrivate;

  /**
   * Convert ValidationErrors object to list, that will be used with errorDictionary
   * @private
   */
  private _getErrorsList(errors: ValidationErrorsPrivate): string[] {
    if (!errors) {
      return [];
    }

    const errorsList: string[] = [];

    Object.entries(errors).forEach(([key, value]) => {
      if (value) {
        errorsList.push(key);
      }
    });

    return errorsList;
  }
}
