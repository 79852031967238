import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonDirective,
  DialogRef,
  DialogService,
  DialogWrapperComponent,
  IconComponent,
  SelectComponent,
  SimpleInputComponent,
  TelInputComponent,
} from '@shared/components';
import { environment } from '@shared/models';
import { ChangeData } from 'ngx-intl-tel-input-gg';
import { GeoService } from '@shared/services';

import { AuthService } from '../../services';
import { ResetPasswordComponent } from '../reset-password';

@Component({
  selector: 'cab-register',
  standalone: true,
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    IconComponent,
    SimpleInputComponent,
    DialogWrapperComponent,
    ButtonDirective,
    ReactiveFormsModule,
    TranslateModule,
    SelectComponent,
    TelInputComponent,
  ],
})
export class RegisterComponent {
  formGroup = new FormGroup({
    name: new FormControl<string>(null, Validators.required),
    email: new FormControl<string>(null, [Validators.required, Validators.email]),
    password: new FormControl<string>(null, [Validators.required]),
    phoneData: new FormControl<ChangeData>(null, [Validators.required]),
    messenger: new FormGroup({
      login: new FormControl<string | null>(null),
      type: new FormControl<string>(null, Validators.required),
    }),
    referral: new FormControl<string>(null),
  });
  messengerFormGroup = this.formGroup.controls.messenger;

  isPasswordVisible = false;
  messengers = [
    { label: 'telegram', value: 'telegram' },
    { label: 'skype', value: 'skype' },
    { label: 'WhatsApp', value: 'whatsapp' },
    { label: 'jabber', value: 'jabber' },
    { label: 'viber', value: 'viber' },
  ];
  messengerWithLoginReuired = ['telegram', 'jabber', 'skype'];
  showMessengerLoginCtrl = true;

  private _authService = inject(AuthService);
  private _destroyRef = inject(DestroyRef);
  private _dialogRef = inject(DialogRef);
  private _dialogService = inject(DialogService);
  private _activatedRoute = inject(ActivatedRoute);
  private _geoService = inject(GeoService);

  constructor() {
    this._populateRefferal();
    this._handleMessengerTypeChange();
  }

  handleClose(): void {
    this._dialogRef.close();
  }

  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  openResetPassword(): void {
    this._dialogRef.close();

    this._dialogService.open(ResetPasswordComponent);
  }

  handleBtnClick(): void {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();

      return;
    }

    const { name, email, password, phoneData, messenger, referral } = this.formGroup.value;
    const language = this._geoService.userGeo.language;

    this._authService
      .register({
        name,
        email,
        password,
        phone: phoneData.e164Number.replace('+', ''),
        messenger,
        referral,
        language,
      })
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: () => this._dialogRef.close(),
        error: err => console.error(err),
      });
  }

  private _populateRefferal(): void {
    const refferal = this._activatedRoute.snapshot.queryParamMap.get(environment.referralKey);
    if (refferal) {
      this.formGroup.patchValue({ referral: refferal }, { emitEvent: false });
    }
  }

  private _handleMessengerTypeChange(): void {
    this.messengerFormGroup.controls.type.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(messenger => {
        this.showMessengerLoginCtrl =
          messenger && this.messengerWithLoginReuired.includes(messenger);

        this.showMessengerLoginCtrl
          ? this.messengerFormGroup.controls.login.setValidators(Validators.required)
          : this.messengerFormGroup.controls.login.removeValidators(Validators.required);
        this.formGroup.updateValueAndValidity();
      });
  }
}
