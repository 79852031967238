import { Directive, HostBinding, Input, booleanAttribute } from '@angular/core';

import { ButtonSize } from './button-size';
import { ButtonType } from './button-types';

@Directive({
  selector: '[cabButton]',
  standalone: true,
})
export class ButtonDirective {
  /**
   * Button size
   */
  @Input() set size(size: ButtonSize) {
    this._size = size;
    this._parentClassList = `cab-button cab-button_${this._size} cab-button_${this._type}`;
  }

  /**
   * Button type
   */
  @Input() set cabButtonType(type: ButtonType) {
    this._type = type;
    this._parentClassList = `cab-button cab-button_${this._size} cab-button_${this._type}`;
  }

  /**
   * Is disabled
   * Use only for links, for button disabled attr should be used
   */
  @HostBinding('class.cab-button_disabled')
  @Input()
  linkDisabled = false;

  /**
   * If button has only icon
   */
  @HostBinding('class.cab-button_only-icon')
  @Input({ transform: booleanAttribute })
  onlyIcon = false;

  /**
   * If button has pressed state
   */
  @HostBinding('class.cab-button_pressed')
  @Input()
  isPressed = false;

  @HostBinding('class.cab-button_active')
  @Input()
  isActive = false;

  /**
   * If button should take full width
   */
  @HostBinding('class.cab-button_full-width')
  @Input({ transform: booleanAttribute })
  fullWidth = false;

  private _size: ButtonSize = 'm';
  private _type: ButtonType = 'primary';

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostBinding('class')
  private _parentClassList = `cab-button cab-button_${this._size} cab-button_${this._type}`;
}
