<img
  class="not-found__bg-img"
  ngSrc="assets/404/planet.svg"
  alt="Not Found"
  height="785"
  width="851"
  priority
/>
<div class="not-found__wrapper">
  <img
    class="not-found__error"
    ngSrc="assets/404/404.svg"
    alt="404"
    height="70"
    width="120"
    priority
  />
  <p class="not-found__text">Sorry, this site is not available in your region</p>
  <a cabButton size="m" cabButtonType="primary" [fullWidth]="true" routerLink="/">{{
    'menu.home' | translate
  }}</a>
</div>
