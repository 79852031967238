import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Review } from '../models/review.interface';

@Component({
  selector: 'cab-review-card',
  standalone: true,
  templateUrl: './review-card.component.html',
  styleUrl: './review-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewCardComponent {
  @Input({ required: true }) reviewItem: Review;
}
