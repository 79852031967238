import { inject, Injectable } from '@angular/core';

import { BehaviorSubject, catchError, Observable, of, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment, GeoResponse } from '@shared/models';
import { GEO_PROVIDER_TOKEN } from '@shared/providers';
import { CountryISO } from 'ngx-intl-tel-input-gg';

@Injectable({
  providedIn: 'root',
})
export class GeoService {
  private _userGeoSubject: BehaviorSubject<GeoResponse> = new BehaviorSubject(null);
  private _userCountryCodeSubject: BehaviorSubject<CountryISO> = new BehaviorSubject(null);

  get userGeo(): GeoResponse {
    return this._userGeoSubject.getValue();
  }

  get userCountryCode(): CountryISO {
    return this._userCountryCodeSubject.getValue();
  }

  private _geoProvider = inject(GEO_PROVIDER_TOKEN);
  private _translateService = inject(TranslateService);

  getGeo(): Observable<GeoResponse> {
    return this._geoProvider.getGeo().pipe(
      tap(geoResponse => {
        this._userGeoSubject.next(geoResponse);
        this._userCountryCodeSubject.next(geoResponse.countryCode);

        this._translateService.use(geoResponse.language.toUpperCase());
      }),
      catchError(error => {
        this._userCountryCodeSubject.next(CountryISO.UnitedStates);
        this._translateService.use(environment.defaultLanguage);

        return of(error);
      }),
    );
  }
}
