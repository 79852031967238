<ng-select
  #select
  [ngClass]="{
    readonly: isReadonly,
    invalid: ngControl.invalid && selectControl.touched && !isDropdownOpened,
  }"
  [items]="items"
  [formControl]="selectControl"
  [clearable]="isClearable"
  [readonly]="isReadonly"
  [placeholder]="placeholder"
  [searchable]="isSearchable"
  [clearSearchOnAdd]="true"
  [id]="controlId"
  [labelForId]="controlId"
  [attr.data-testid]="controlTestId"
  bindLabel="label"
  bindValue="value"
  appendTo="body"
  [notFoundText]="'general.noItems' | translate"
  [clearAllText]="'general.clearAll' | translate"
  [virtualScroll]="true"
  (open)="changeDropdownState()"
  (close)="changeDropdownState()"
  (clear)="select.close()"
  (scrollToEnd)="scrollEnd.emit()"
/>

@if (selectControl.touched && ngControl.invalid && showValidationMessage && !isDropdownOpened) {
  <cab-validation-message [errors]="ngControl.errors" [errorDictionary]="errorDictionary" />
}
