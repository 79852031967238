import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

import { map, Observable } from 'rxjs';

/**
 * Common PU breakpoints
 */
export enum CommonMediaQueries {
  XS = 320,
  SM = 375,
  MD = 768,
  XL = 1366,
  XXL = 1920,
}

@Injectable({
  providedIn: 'root',
})
export class MediaScreenService {
  constructor(private _breakpointObserver: BreakpointObserver) {}

  mediaMatcher(breakpoint: CommonMediaQueries | number): Observable<boolean> {
    return this._breakpointObserver
      .observe(`(min-width: ${breakpoint}px)`)
      .pipe(map(({ matches }: BreakpointState) => matches));
  }

  isMatched(breakpoint: CommonMediaQueries | number): boolean {
    return this._breakpointObserver.isMatched(`(min-width: ${breakpoint}px)`);
  }
}
