import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgClass } from '@angular/common';

import { ButtonDirective, IconComponent } from '@shared/components';
import { slideInAnimation, slideOutAnimation } from '@shared/ui';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarOverlayService } from '@shared/services';

import { NavigationService } from '../../services/navigation.service';
import { LanguagesComponent } from '../languages/languages.component';
import { NavComponent } from '../nav/nav.component';
import { UserMenuComponent } from '../user-menu/user-menu.component';
import { SocialLinksComponent } from '../social-links/social-links.component';

@Component({
  selector: 'cab-navigation-mobile',
  standalone: true,
  templateUrl: './navigation-mobile.component.html',
  styleUrl: './navigation-mobile.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideInAnimation, slideOutAnimation],
  providers: [NavigationService],
  imports: [
    IconComponent,
    TranslateModule,
    NgClass,
    ButtonDirective,
    LanguagesComponent,
    NavComponent,
    UserMenuComponent,
    SocialLinksComponent,
  ],
})
export class NavigationMobileComponent {
  private readonly _sideBarOverlayService = inject(SidebarOverlayService);

  onClose(): void {
    this._sideBarOverlayService.close();
  }
}
