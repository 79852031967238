<cab-section-header class="header" [title]="'conferences.header' | translate" />
<div class="content">
  <div class="conference">
    <ul class="conference__list" [ngStyle]="{ height: listHeight }" [class.mobile]="!isDesktopMode">
      @for (conference of conferenceList; track idx; let idx = $index) {
        <li
          class="conference__item item"
          [ngStyle]="{ transform: getTopPosition(idx) }"
          (mouseenter)="onMouseEnter(idx)"
          (mouseleave)="onMouseLeave()"
        >
          <div class="item__header">
            <img
              loading="eager"
              [src]="conference.logoUrl"
              alt="conference logo"
              class="item__logo"
            />
          </div>
          <div class="item__body">
            <span>{{ conference.title }}</span>
          </div>
          <div class="item__footer">
            <span class="item__date">
              {{ conference.date.from | date: 'dd.MM.yyyy' }} -
              {{ conference.date.to | date: 'dd.MM.yyyy' }}
            </span>
            <span class="item__location">{{ conference.location }}</span>
          </div>
        </li>
      }
    </ul>
  </div>
</div>
