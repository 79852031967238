import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TranslateModule } from '@ngx-translate/core';
import { finalize } from 'rxjs';
import { DecodedSafeHtmlPipe } from '@shared/ui';

import { StaticContentPage } from '../../models/static-content-page.enum';
import { StaticContentService } from '../../services/static-content.service';
import { SpinnerDirective } from '@shared/components';

@Component({
  selector: 'cab-doc-page',
  standalone: true,
  templateUrl: './doc-page.component.html',
  styleUrl: './doc-page.component.scss',
  imports: [DecodedSafeHtmlPipe, SpinnerDirective, TranslateModule],
  providers: [StaticContentService],
  encapsulation: ViewEncapsulation.None,
})
export class DocPageComponent implements OnInit {
  @Input({ required: true }) page: StaticContentPage;

  content: string;
  isLoading = true;
  hasError = false;

  private _destroyRef = inject(DestroyRef);
  private _staticContentService = inject(StaticContentService);
  private _crd = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this._staticContentService
      .getPageContent(this.page)
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        finalize(() => {
          this.isLoading = false;
          this._crd.markForCheck();
        }),
      )
      .subscribe({
        next: content => (this.content = content),
        error: () => (this.hasError = true),
      });
  }
}
