import { inject, Injectable } from '@angular/core';

import { LoginComponent, RegisterComponent } from '@auth/core';
import { DialogService } from '@shared/components';

import { UserAction } from '../models/user-action.interface';
import { NavigationLink } from '../models/navigation-link.interface';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private _dialogService = inject(DialogService);

  getNavigationLinks(): NavigationLink[] {
    return [
      {
        label: 'navigation.main',
        link: '/',
      },
      {
        label: 'navigation.reviewPP',
        link: '/',
      },
      {
        label: 'navigation.events',
        link: '/',
      },
      {
        label: 'navigation.reviews',
        link: '/',
      },
      {
        label: 'navigation.blog',
        link: '/',
      },
      {
        label: 'navigation.connectWithUs',
        link: '/',
      },
    ];
  }

  getUserActions(): UserAction[] {
    return [
      {
        label: 'navigation.registration',
        isActive: true,
        action: () => this._dialogService.open(RegisterComponent),
      },
      {
        label: 'navigation.login',
        action: () => this._dialogService.open(LoginComponent),
      },
    ];
  }
}
