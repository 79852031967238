<a routerLink="/">
  <img class="header__logo" src="/assets/logo-partners.svg" alt="logo" />
</a>

@if (isDesktopMode) {
  <nav class="nav" cabNav [class.nav_desktop]="isDesktopMode"></nav>

  <menu class="menu" cabUserMenu [class.menu_desktop]="isDesktopMode">
    <cab-languages />
  </menu>
} @else {
  <cab-icon class="header__menu-icon" iconName="burger" size="xl" (click)="openSideMenu()" />
}
