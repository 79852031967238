import { Component, inject, OnInit } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonDirective } from '@shared/components';

import { NavigationService } from '../../services/navigation.service';
import { UserAction } from '../../models/user-action.interface';

@Component({
  selector: 'cab-user-menu, [cabUserMenu]',
  standalone: true,
  templateUrl: './user-menu.component.html',
  styleUrl: './user-menu.component.scss',
  imports: [TranslateModule, ButtonDirective],
})
export class UserMenuComponent implements OnInit {
  actions: UserAction[];

  private _navigationService = inject(NavigationService);

  ngOnInit(): void {
    this.actions = this._navigationService.getUserActions();
  }

  handleItemClick(item: UserAction): void {
    if (item.action) {
      item.action();
    }
  }
}
