<div class="advertisement-line__container">
  @for (item of lineCount; track $index) {
    <div class="advertisement-line__list">
      @for (item of advertisementTags; track $index) {
        <div class="advertisement-line__item-wrapper">
          <span>{{ item | translate }}</span>
          <cab-icon iconName="asterisk" size="xl" />
        </div>
      }
    </div>
  }
</div>
