<div class="start__wrapper">
  <div class="start__content">
    <h1 class="start__caption">
      <span class="start__caption_stroke">{{ 'start.caption-default' | translate }}</span>
      <br />
      <span class="start__caption_highlighted">{{ 'start.caption-highlighted' | translate }}</span>
    </h1>
    <div class="start__cta-wrapper">
      <div class="start__offers"></div>
      <button
        class="start__cta"
        cabButton
        cabButtonType="tertiary"
        [size]="isDesktopMode ? 'cta' : 's'"
        (click)="openRegistrationDialog()"
      >
        {{ 'start.button' | translate }}
      </button>
    </div>
  </div>
  <img class="start__gif" src="/assets/pin-up-world.gif" alt="Pin-Up World" />
</div>
<cab-advertisement-line class="start__line" />
