import { Environment } from '../enviroment.interface';
import { Languages } from '../languages.enum';

export const environment: Environment = {
  production: false,
  apiDomain: 'https://cabinet-api.pinup-partners-dev.com',
  apiHost: '/api/v2',
  defaultLanguage: Languages.RU,
  referralKey: 'referral',
  cabinetUrl: 'https://cabinet2.pinup-partners-dev.com',
};
