import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import {
  ButtonDirective,
  DialogRef,
  DialogService,
  DialogWrapperComponent,
  IconComponent,
  SimpleInputComponent,
} from '@shared/components';
import { AuthService } from '../../services';
import { LoginComponent } from '../login';

@Component({
  selector: 'cab-reset-password',
  standalone: true,
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    IconComponent,
    SimpleInputComponent,
    DialogWrapperComponent,
    ButtonDirective,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class ResetPasswordComponent {
  formGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  isResetSuccess = false;

  private _authService = inject(AuthService);
  private _destroyRef = inject(DestroyRef);
  private _dialogRef = inject(DialogRef);
  private _dialogService = inject(DialogService);

  handleClose(): void {
    this._dialogRef.close();
  }

  openLoginModal(): void {
    this._dialogRef.close();
    this.isResetSuccess = false;
    this._dialogService.open(LoginComponent);
  }

  handleBtnClick(): void {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();

      return;
    }

    this.isResetSuccess = true;
    const { email } = this.formGroup.value;
    this._authService
      .resetPassword({ email })
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: () => {
          this.isResetSuccess = true;
        },
        error: error => console.error(error), // TODO: add toaster
      });
  }
}
