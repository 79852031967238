import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { StrongProductComponent } from '@sections/strong-product';
import { StartComponent } from '@sections/start';
import { OffersComponent } from '@sections/offers';
import { CareerComponent } from '@sections/career';
import { FAQComponent } from '@sections/faq';
import { ReviewsComponent } from '@sections/reviews';
import { ConferencesComponent } from '@sections/conferences';
import { SliderComponent } from '@sections/slider';
import { Observable } from 'rxjs';
import { CommonMediaQueries, MediaScreenService } from '@shared/services';

@Component({
  selector: 'cab-home',
  standalone: true,
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    StrongProductComponent,
    StartComponent,
    OffersComponent,
    CareerComponent,
    FAQComponent,
    ReviewsComponent,
    ConferencesComponent,
    SliderComponent,
    AsyncPipe,
  ],
})
export class HomeComponent implements OnInit {
  isDesktopMode$: Observable<boolean>;

  private _mediaScreenService = inject(MediaScreenService);

  ngOnInit(): void {
    this.isDesktopMode$ = this._mediaScreenService.mediaMatcher(CommonMediaQueries.XL);
  }
}
