import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

import { SidebarOverlayService } from '@shared/services';
import { ButtonDirective, IconComponent } from '@shared/components';
import { TranslateModule } from '@ngx-translate/core';

import { NavigationService } from '../../services/navigation.service';
import { NavigationMobileComponent } from '../navigation-mobile/navigation-mobile.component';
import { LanguagesComponent } from '../languages/languages.component';
import { NavComponent } from '../nav/nav.component';
import { UserMenuComponent } from '../user-menu/user-menu.component';

@Component({
  selector: 'cab-header, [cabHeader]',
  standalone: true,
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NavigationService, SidebarOverlayService],
  imports: [
    AsyncPipe,
    IconComponent,
    TranslateModule,
    NgClass,
    ButtonDirective,
    LanguagesComponent,
    RouterLink,
    NavComponent,
    UserMenuComponent,
  ],
})
export class HeaderComponent {
  @Input({ required: true }) isDesktopMode: boolean;

  private readonly _sideBarOverlayService = inject(SidebarOverlayService);

  openSideMenu(): void {
    this._sideBarOverlayService.open(NavigationMobileComponent);
  }
}
