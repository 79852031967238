import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, map } from 'rxjs';
import { GeoAdapter } from '@shared/adapters';

import { GeoProvider } from './geo.provider';
import { GeoResponse, GeoRestResponse } from '@shared/models';

@Injectable()
export class HttpGeoProvider implements GeoProvider {
  private _baseUrl = `https://ipapi.co/json`;
  private _httpClient = inject(HttpClient);

  getGeo(): Observable<GeoResponse> {
    return this._httpClient.get<GeoRestResponse>(this._baseUrl).pipe(
      map((response: GeoRestResponse) => {
        return GeoAdapter.fromRest(response);
      }),
    );
  }
}
