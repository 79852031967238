import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { environment } from '@shared/models';
import { Observable, map } from 'rxjs';

import { AuthAdapter } from '../adapters';
import {
  JwtTokens,
  JwtTokensRest,
  LoginRequest,
  RegisterRequest,
  ResetPasswordRequest,
} from '../models';
import { getAccessToken } from '../utils';
import { AuthProvider } from './auth.provider';

@Injectable()
export class HttpAuthProvider implements AuthProvider {
  private _baseUrl = `${environment.apiHost}/auth`;

  private _httpClient = inject(HttpClient);

  isTokenValid(): Observable<boolean> {
    return this._httpClient
      .get<boolean>(`${this._baseUrl}/webmaster`, {
        headers: { Authorization: `Bearer ${getAccessToken}` },
      })
      .pipe(map(Boolean));
  }

  login(request: LoginRequest): Observable<JwtTokens> {
    return this._httpClient
      .post<JwtTokensRest>(`${this._baseUrl}/jwt/login`, AuthAdapter.loginToRest(request))
      .pipe(map(response => AuthAdapter.jwtTokensFromRest(response)));
  }

  register(request: RegisterRequest): Observable<void> {
    return this._httpClient.post<void>(
      `${this._baseUrl}/register`,
      AuthAdapter.registerToRest(request),
    );
  }

  resetPassword(request: ResetPasswordRequest): Observable<void> {
    return this._httpClient.post<void>(
      `${this._baseUrl}/password/reset`,
      AuthAdapter.resetPasswordToRest(request),
    );
  }
}
