<div class="header">
  <span class="title">{{ caption }}</span>
</div>

<div class="ng-content body">
  <ng-content select="[dialogBody]" />
</div>
<div class="ng-content"><ng-content select="[dialogDefault]" /></div>
<div class="ng-content footer">
  <ng-content select="[dialogFooter]" />
</div>

@if (shouldShowCloseBtn) {
  <button
    cabButton
    cabButtonType="link"
    size="s"
    [onlyIcon]="true"
    class="close-btn"
    (click)="closeDialog.emit()"
    type="button"
    [attr.data-testid]="dialogName + 'dialogCloseBtn'"
  >
    <cab-icon iconName="close" />
  </button>
}
