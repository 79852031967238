import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { AsyncPipe, NgOptimizedImage } from '@angular/common';

import { AdvertisementLineComponent, ButtonDirective, DialogService } from '@shared/components';
import { TranslateModule } from '@ngx-translate/core';
import { RegisterComponent } from '@auth/core';

@Component({
  selector: 'cab-start',
  standalone: true,
  templateUrl: './start.component.html',
  styleUrl: './start.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ButtonDirective,
    TranslateModule,
    AsyncPipe,
    NgOptimizedImage,
    AdvertisementLineComponent,
  ],
})
export class StartComponent {
  @Input({ required: true }) isDesktopMode: boolean;

  private readonly _dialogService = inject(DialogService);

  openRegistrationDialog(): void {
    this._dialogService.open(RegisterComponent);
  }
}
