export enum StaticContentPage {
  PrivacyPolicy,
  AffiliateAgreement,
}

export const staticContentPageRoutes: Record<StaticContentPage, string> = {
  [StaticContentPage.PrivacyPolicy]: '/privacy-policy',
  [StaticContentPage.AffiliateAgreement]: '/affiliate-agreement',
};

export const staticContentPath: Record<StaticContentPage, string> = {
  [StaticContentPage.PrivacyPolicy]:
    '/assets/static-pages/privacy-policy/{lang}-privacy-policy.html',
  [StaticContentPage.AffiliateAgreement]:
    '/assets/static-pages/affiliate-agreement/{lang}-affiliate-agreement.html',
};
