import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { StaticContentPage, staticContentPath } from '../models/static-content-page.enum';

@Injectable()
export class StaticContentService {
  private _httpClient = inject(HttpClient);
  private _translateService = inject(TranslateService);

  getPageContent(page: StaticContentPage): Observable<string> {
    const lang = this._translateService.currentLang.toLowerCase();
    const staticPageUrl = staticContentPath[page];

    if (!staticPageUrl) {
      throw new Error(`Static page not found`);
    }

    const staticPageUrlWithLang = staticPageUrl.replace('{lang}', lang);
    const headers = new HttpHeaders({
      Accept: 'text/html',
      'Content-Type': 'text/html',
    });

    return this._httpClient.get(staticPageUrlWithLang, { headers, responseType: 'text' });
  }
}
