import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgOptimizedImage } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonDirective } from '@shared/components';

@Component({
  selector: 'cab-not-found',
  standalone: true,
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonDirective, TranslateModule, RouterLink, NgOptimizedImage],
})
export class NotFoundComponent {}
