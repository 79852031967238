export enum Languages {
  EN = 'EN',
  RU = 'RU',
  UA = 'UA',
  PT = 'PT',
  ES = 'ES',
  BR = 'BR',
  IN = 'IN',
  PE = 'PE',
}

export const languageList: Languages[] = Object.values(Languages);
export const languageKeyList: string[] = Object.keys(Languages);

export const languagesLocaleRecord: Record<Languages, string> = {
  [Languages.RU]: 'ru',
  [Languages.UA]: 'uk',
  [Languages.EN]: 'en',
  [Languages.BR]: 'pt',
  [Languages.ES]: 'es',
  [Languages.IN]: 'hi',
  [Languages.PT]: 'pt',
  [Languages.PE]: 'es',
};
