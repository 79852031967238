import { LetDirective } from './let.directive';

export interface ContextWithImplicit<T> {
  $implicit: T;
}

/**
 * @internal
 */
export class LetContext<T> implements ContextWithImplicit<T> {
  constructor(private readonly _internalDirectiveInstance: LetDirective<T>) {}

  get $implicit(): T {
    return this._internalDirectiveInstance.cabLet;
  }

  get cabLet(): T {
    return this._internalDirectiveInstance.cabLet;
  }
}
