import { ChangeDetectionStrategy, Component, computed, inject, Input, Signal } from '@angular/core';
import { AsyncPipe, DatePipe, NgStyle } from '@angular/common';
import { SectionHeaderComponent } from '@shared/components';
import { TranslateModule } from '@ngx-translate/core';
import { Conference } from './models/conference.interface';
import { CONFERENCE_LIST } from './const/conferences.constants';
import { CommonMediaQueries, MediaScreenService } from '@shared/services';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'cab-conferences',
  standalone: true,
  templateUrl: './conferences.component.html',
  styleUrl: './conferences.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgStyle, DatePipe, SectionHeaderComponent, TranslateModule],
})
export class ConferencesComponent {
  @Input({ required: true }) set isDesktopMode(isDesktopMode: boolean) {
    this._isDesktopMode = isDesktopMode;

    this.listHeight = this._getListHeight(isDesktopMode);
  }

  get isDesktopMode(): boolean {
    return this._isDesktopMode;
  }

  conferenceList: Conference[] = CONFERENCE_LIST;
  hoveredIndex: number | null = null;
  listHeight: string;

  private _isDesktopMode: boolean;

  getTopPosition(index: number): string {
    if (!this.isDesktopMode) {
      return `translateY(0)`;
    }

    if (this.hoveredIndex !== null && index > this.hoveredIndex) {
      return `translateY(-${index * 15 - 14.5}rem)`;
    }

    return `translateY(-${index * 15}rem)`;
  }

  onMouseEnter(index: number): void {
    this.hoveredIndex = index;
  }

  onMouseLeave(): void {
    this.hoveredIndex = null;
  }

  private _getListHeight(isDesktopMode: boolean): string {
    const listHeight = isDesktopMode ? 20 + this.conferenceList.length * 5 : 17.5;

    return `${listHeight}rem`;
  }
}
