import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { IconComponent, SectionHeaderComponent } from '@shared/components';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { FAQItem } from './faq-item';
import { DecodedSafeHtmlPipe } from '@shared/ui';

@Component({
  selector: 'cab-faq',
  standalone: true,
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, SectionHeaderComponent, IconComponent, DecodedSafeHtmlPipe],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          maxHeight: '1000px',
          opacity: 1,
          visibility: 'visible',
          padding: '12px 12px 0',
        }),
      ),
      state(
        'closed',
        style({
          maxHeight: '0px',
          opacity: 0,
          visibility: 'hidden',
          padding: '0 12px',
        }),
      ),
      transition('open <=> closed', [animate('0.3s ease-in-out')]),
    ]),
  ],
})
export class FAQComponent {
  @Input({ required: true }) isDesktopMode: boolean;

  readonly items: FAQItem[] = [
    {
      title: 'faq.questionOne',
      content: 'faq.answerOne',
      open: false,
    },
    {
      title: 'faq.questionTwo',
      content: 'faq.answerTwo',
      open: false,
    },
    {
      title: 'faq.questionThree',
      content: 'faq.answerThree',
      open: false,
    },
    {
      title: 'faq.questionFour',
      content: 'faq.answerFour',
      open: false,
    },
    {
      title: 'faq.questionFive',
      content: 'faq.answerFive',
      open: false,
    },
    {
      title: 'faq.questionSix',
      content: 'faq.answerSix',
      open: false,
    },
    {
      title: 'faq.questionSeven',
      content: 'faq.answerSeven',
      open: false,
    },
    {
      title: 'faq.questionEight',
      content: 'faq.answerEight',
      open: false,
    },
    {
      title: 'faq.questionNine',
      content: 'faq.answerNine',
      open: false,
    },
    {
      title: 'faq.questionTen',
      content: 'faq.answerTen',
      open: false,
    },
    {
      title: 'faq.questionEleven',
      content: 'faq.answerEleven',
      open: false,
    },
    {
      title: 'faq.questionTwelve',
      content: 'faq.answerTwelve',
      open: false,
    },
    {
      title: 'faq.questionThirteen',
      content: 'faq.answerThirteen',
      open: false,
    },
    {
      title: 'faq.questionFourteen',
      content: 'faq.answerFourteen',
      open: false,
    },
    {
      title: 'faq.questionFifteen',
      content: 'faq.answerFifteen',
      open: false,
    },
    {
      title: 'faq.questionSixteen',
      content: 'faq.answerSixteen',
      open: false,
    },
    {
      title: 'faq.questionSeventeen',
      content: 'faq.answerSeventeen',
      open: false,
    },
    {
      title: 'faq.questionEighteen',
      content: 'faq.answerEighteen',
      open: false,
    },
  ];

  toggleItem(item: FAQItem) {
    item.open = !item.open;
  }
}
