import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { IconSize } from './models';

@Component({
  selector: 'cab-icon',
  templateUrl: './icon.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
})
export class IconComponent {
  /**
   * @internal
   */
  @HostBinding('class.cabinet-icon') hostClass = true;
  /**
   * Corresponds to svg file name inside /icons/svg-icons.
   */
  @Input({ required: true }) iconName: string;

  /**
   * Icon size
   */
  @Input() size: IconSize = 'm';
}
