import { Component, Input } from '@angular/core';

import { IconComponent, IconSize } from '@shared/components';

@Component({
  selector: 'cab-social-links',
  standalone: true,
  templateUrl: './social-links.component.html',
  styleUrl: './social-links.component.scss',
  imports: [IconComponent],
})
export class SocialLinksComponent {
  @Input() size: IconSize = 's';
}
