import { LocalStorageKeys } from './local-storage-keys';

export const getAccessToken = (): string => {
  return localStorage.getItem(LocalStorageKeys.accessToken) ?? '';
};

export const getRefreshToken = (): string => {
  return localStorage.getItem(LocalStorageKeys.refreshToken) ?? '';
};

export const setRefreshToken = (token: string): void => {
  localStorage.setItem(LocalStorageKeys.refreshToken, token);
};

export const setAccessToken = (token: string): void => {
  localStorage.setItem(LocalStorageKeys.accessToken, token);
};

export const clearAccessToken = (): void => {
  localStorage.removeItem(LocalStorageKeys.accessToken);
};

export const clearRefreshToken = (): void => {
  localStorage.removeItem(LocalStorageKeys.refreshToken);
};

export const clearTokens = (): void => {
  clearAccessToken();
  clearRefreshToken();
};
