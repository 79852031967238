import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { JwtTokens } from '../models';
import { AuthProvider } from './auth.provider';

@Injectable()
export class MockAuthProvider implements AuthProvider {
  isTokenValid(): Observable<boolean> {
    return of(false);
  }

  register(): Observable<void> {
    return of(null);
  }

  login(): Observable<JwtTokens> {
    return of({
      accessToken: '',
      refreshToken: '',
    });
  }

  resetPassword(): Observable<void> {
    return of(null);
  }
}
