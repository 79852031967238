import { trigger, state, style, transition, animate } from '@angular/animations';

export const accordionAnimation = trigger('accordionContent', [
  state(
    'open',
    style({
      maxHeight: '1000px', // set a large enough value
      opacity: 1,
      padding: '10px',
    }),
  ),
  state(
    'closed',
    style({
      maxHeight: '0px',
      opacity: 0,
      padding: '0px 10px',
    }),
  ),
  transition('open <=> closed', [animate('0.3s ease-in-out')]),
]);
