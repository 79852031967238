import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { languageList } from '@shared/models';

@Component({
  selector: 'cab-languages',
  standalone: true,
  templateUrl: './languages.component.html',
  styleUrl: './languages.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgSelectModule, ReactiveFormsModule],
})
export class LanguagesComponent implements OnInit {
  @Input() isMobile = false;

  readonly translateService = inject(TranslateService);

  languageControl = new FormControl({
    label: this.translateService.currentLang,
    value: this.translateService.currentLang,
  });

  languages = languageList.map(lang => ({
    label: lang,
    value: lang,
  }));

  private _destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.languageControl.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(lang => {
      this.translateService.use(lang.value);
    });
  }
}
