import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

import { DialogSize, DialogType } from './dialog-wrapper';
import { IconComponent } from '../../icon';
import { ButtonDirective } from '../../button';

@Component({
  selector: 'cab-dialog-wrapper',
  standalone: true,
  templateUrl: './dialog-wrapper.component.html',
  styleUrls: ['./dialog-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconComponent, ButtonDirective],
})
export class DialogWrapperComponent {
  @Input({ required: true }) caption: string;
  @Input() dialogName = '';
  @Input() shouldShowCloseBtn = true;
  @Input() shouldShowSpinner = false;

  /**
   * Dialog size
   */
  @Input() set size(size: DialogSize) {
    this._size = size;
    this._parentClassList = `cabinet-dialog size_${this._size} cabinet-dialog_${this._type}`;
  }

  /**
   * Dialog type
   */
  @Input() set type(type: DialogType) {
    this._type = type;
    this._parentClassList = `cabinet-dialog size_${this._size} cabinet-dialog_${this._type}`;
  }

  @Output() closeDialog = new EventEmitter<void>();

  private _type: DialogType = 'default';
  private _size: DialogSize = 'm';

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostBinding('class')
  private _parentClassList = `cabinet-dialog size_${this._size} cabinet-dialog_${this._type}`;
}
